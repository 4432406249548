import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        contactTitleLine1
        contactContent
        contactLabel
        contactUrl
        contactMap
      }
    }
  `)
  const {
    contactContent,
    contactLabel,
    contactTitleLine1,
    contactUrl,
    contactMap,
  } = data.contentfulHomePage
  return (
    <section className="contact-sec">
      <div className="ellipse"></div>
      <div className="container">
        <div className="row">
          <div className="col col-md-6 d-flex align-items-center">
            <div>
              <h2>{contactTitleLine1}</h2>
              <p>{contactContent}</p>
              <a href={contactUrl}>
                <button type="button" className="btn d-btn">
                  {contactLabel}
                </button>
              </a>
            </div>
          </div>
          <div className="col col-md-6">
             <label>&nbsp;</label>
            <iframe
              width="605"
              height="575"
              style={{ frameBorder: "0" }}
              loading="lazy"
              allowFullScreen
              src={contactMap}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
