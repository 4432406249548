import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const QuickLinks = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        linksTitle1
        linksTitle2
        linksContent {
          id
          title
          content {
            content
          }
          url
        }
      }
    }
  `)
  const { linksTitle1, linksTitle2, linksContent } = data.contentfulHomePage
  return (
    <section className="link-sec">
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center justify-content-betweens">
            <div className="link-sec-head">
              <h6>
                <span>{linksTitle1}</span> 
              </h6>
              <h5>{linksTitle2}</h5>
            </div>
            <div className="link-sec-boxmain d-flex">
              {linksContent.map(item => (
                <a
                  href={item.url}
                  style={{ display: "contents" }}
                  key={item.id}
                >
                  <div className="link-sec-box">
                    <h6>{item.title}</h6>
                    <p>{item.content.content}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuickLinks
