import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Commitments = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        commitmentTitle
        commitmentContent {
          commitmentContent
        }
        commitmentImage1 {
          file {
            url
          }
        }
        commitmentLargeImage1 {
          file {
            url
          }
        }
        commitmentImage2 {
          file {
            url
          }
        }
        commitmentLargeImage2 {
          file {
            url
          }
        }
        commitments {
          id
          image {
            file {
              url
            }
          }
          title
          content {
            content
          }
        }
      }
    }
  `)
  const {
    commitmentTitle,
    commitmentContent,
    commitmentImage1,
    commitmentLargeImage1,
    commitmentImage2,
    commitmentLargeImage2,
    commitments,
  } = data.contentfulHomePage
  return (
    <section className="commit-sec">
      <div className="container">
        <div className="row">
          <div className="col col-md-6 commit-sec-image hidden-xs">
            <div className="commit-sec-image1">
              <img src={commitmentImage1.file.url} alt="#" />
            </div>
            <div className="commit-sec-image2">
              <img src={commitmentLargeImage1.file.url} alt="#" />
            </div>
          </div>
          <div className="col col-md-6 commit-sec-content">
            <h2>{commitmentTitle}</h2>
            <p>{commitmentContent.commitmentContent}</p>
            <ul className="hidden-xs">
              {commitments.map(commitment => (
                <li className="d-flex" key={commitment.id}>
                  <div className="icon-wrapper">
                    <img src={commitment.image.file.url} />
                  </div>
                  <div>
                    <h6>{commitment.title}</h6>
                    <p>{commitment.content.content}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="col col-md-6 commit-sec-image visible-xs">
            <div className="commit-sec-image1">
              <img src={commitmentImage2.file.url} alt="#" />
            </div>
            <div className="commit-sec-image2">
              <img src={commitmentLargeImage2.file.url} alt="#" />
            </div>
          </div>
          <div className="col col-md-6 commit-sec-content">
            <ul className="visible-xs">
              {commitments.map(commitment => (
                <li className="d-flex" key={commitment.id}>
                  <div className="icon-wrapper-xs">
                    <img src={commitment.image.file.url} />
                  </div>
                  <div>
                    <h6>{commitment.title}</h6>
                    <p>{commitment.content.content}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Commitments
