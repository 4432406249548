import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import MetaCards from "../components/common/meta-cards"
import Helmet from "react-helmet"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Hero from "../components/home/hero"
import Health from "../components/shared/health"
import QuickLinks from "../components/home/quick_links"
import Appointment from "../components/home/appointment"
import Testimonials from "../components/shared/testimonials"
import Commitments from "../components/home/commitments"
import Contact from "../components/home/contact"
import TipsFromTeam from "../components/shared/tips_from_team"
import Visit from "../components/shared/visit"

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <Seo />
      <MetaCards data={data.contentfulHomePage.webMeta}/>
      <Hero />
      <Health data={data.contentfulHomePage} />
      <QuickLinks />
      <Appointment />
      <Testimonials data={data.contentfulHomePage} />
      <Commitments />
      <Contact />
      <TipsFromTeam data={data.contentfulHomePage.tipsFromTeam} />
      <Visit data={data.contentfulHomePage.scheduleAVisit} />
      <Helmet>
        <script>
          {`(function (v, e, t, s) {
            console.log("Script loaded");
          e.televet = e.televet || { id: v }; 
          var o = t.createElement('script'); 
          o.async = true; 
          o.src = 'https://connect.televet.com/shim.js'; 
          var r = t.getElementsByTagName('script')[0]; 
          r.parentNode.insertBefore(o, r); 
        })('clj6i37to03wtyy01tgoe7u5e', window, document);`}
        </script>
      </Helmet>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    contentfulHomePage {
      webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      healthSliderHeadline {
        raw
      }
      healthSlider {
        id
        title
        content {
          content
        }
        linkLabel
        linkUrl
        image {
          file {
            url
          }
        }
      }

      testimonialTitle
      testimonials {
        id
        testimonial {
          testimonial
        }
        client
      }

      tipsFromTeam {
        title
        content
        tips {
          id
          label
          shortDescription
          url
          image {
            file {
              url
            }
          }
        }
      }

      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
      }
    }
  }
`