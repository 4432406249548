import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const Appointment = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        appointmentTitle
        appointmentContent {
          appointmentContent
        }
        appointments {
          id
          title
          image {
            file {
              url
            }
          }
          content {
            content
          }
          linkLabel
          linkUrl
        }
      }
    }
  `)
  const { appointmentTitle, appointmentContent, appointments } =
    data.contentfulHomePage

  const [idx, setIdx] = useState(0)
  return (
    <section className="appointment-sec">
      <div className="container">
        <div className="row">
          <div className="col col-md-6 appointment-sec-l d-flex align-items-center">
            <div>
              <h2>{appointmentTitle}</h2>
              <p>{appointmentContent.appointmentContent}</p>
              <ul>
                {appointments.map((item, i) => (
                  <li key={item.id} onClick={() => setIdx(i)}>
                    <button
                      className={idx === i && "active"}
                      type="button"
                      target={`.appointment-sec-box${i + 1}`}
                    >
                      {item.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col col-md-6 appointment-sec-r">
            <div className="appointment-sec-listbox">
              {appointments.map((item, i) => (
                <div
                  className={`appointment-sec-box appointment-sec-box${i + 1} ${
                    idx === i && "active"
                  }`}
                  key={item.id}
                >
                  <img src={item.image.file.url} alt="#" />
                  <div className="appointment-sec-box-inner">
                    <h6>{item.title}</h6>
                    <p>{item.content.content}</p>
                    <a href={item.linkUrl}>
                      {item.linkLabel}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.243"
                        height="13.501"
                        viewBox="0 0 20.243 13.501"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-round-forward"
                          data-name="Icon ionic-ios-arrow-round-forward"
                          d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                          transform="translate(-7.875 -11.252)"
                          fill="#ff0245"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Appointment
